<app-page-base title="Over Heemkring Scheldeveld"></app-page-base>

<section class="mt-4 container">
   <h1>Doel van de vereniging</h1>
   <p>Ontsluiting en bewaring van de heemkundige gegevens van de 9 dorpen die het werkgebied van de vereniging omvatten: Afsnee, De Pinte, Deurle, Eke, Nazareth, Sint-Denijs-Westrem, Sint-Martens-Latem, Zevergem en Zwijnaarde.</p>
</section>

<section class="container">
   <h1>Activiteiten</h1>
   <ul>
      <li>publiceren van een jaarboek</li>
      <li>organiseren van uitstappen en wandelingen</li>
      <li>inrichten van tentoonstellingen over heemkundige aspecten</li>
      <li>inventariseren en conserveren van voorwerpen  en geschriften die kunnen bijdragen tot de kennis van het heem zoals prentbriefkaarten, affiches, foto’s, landkaarten, …</li>
      <li>meewerken aan het Gemeentelijk Documentatie- en Archiefcentrum van Sint-Martens-Latem.</li>
   </ul>
</section>

<section class="container">
   <h1>Lid worden</h1>
   <ul>
      <li>Gewoon lidmaatschap: €12,40</li>
      <li>Beschermd lidmaatschap: €18,60</li>
      <li>Ere-lidmaatschap: €24,80</li>
      <li>Jaarboek voor niet-leden: €15,00</li>
   </ul>
</section>