<app-page-base [title]="city.name">
</app-page-base>

<section class="container">
   <h2>Alle artikels gerelateerd aan {{city.name}}:</h2>
   <ul>
      <li *ngFor="let article of city.articles">
         {{article}}
      </li>
   </ul>

   <div class="hr my-5"></div>

   <h2>Algemene artikels:</h2>
   <ul>
      <li *ngFor="let article of general.articles">
         {{article}}
      </li>
   </ul>
</section>