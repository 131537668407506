<app-page-base>
   <app-map></app-map>
</app-page-base>

<section class="container text-center">
   <h1>Doel van de vereniging</h1>
   <p>Ontsluiting en bewaring van de heemkundige gegevens van de 9 dorpen die het werkgebied van de vereniging omvatten: Afsnee, De Pinte, Deurle, Eke, Nazareth, Sint-Denijs-Westrem, Sint-Martens-Latem, Zevergem en Zwijnaarde.</p>
   <a class="mt-4 btn btn-secondary" href='over'>Over ons</a>
</section>

<div class="hr"></div>

<section class="container text-center">
   <h1>Met steun van</h1>
   <div class="logos">
      <img src="../assets/media/sponsors/DePinte.jpg">
      <img src="../assets/media/sponsors/Cultuurregio.jpg">
      <img src="../assets/media/sponsors/GENT.jpg">
      <img src="../assets/media/sponsors/latem.jpg">
      <img src="../assets/media/sponsors/nazareth.jpg">
      <img src="../assets/media/sponsors/Vlaanderen.jpg">
   </div>
</section>